<template>
  <section class="invoice-preview-wrapper" v-if="result.holder">
    <b-row class="invoice-preview">
      <b-col
        cols="12"
        :xl="result.status == 'pending' ? 9 : 12"
        :md="result.status == 'pending' ? 8 : 12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-0">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper mb-0">
                  <!-- User Avatar -->
                  <div class="d-flex justify-content-start">
                    <b-avatar
                      :src="result.holder ? result.holder.photo_url : ''"
                      :text="result.holder.name != null ? result.holder.name.charAt(0) : '-'"
                      :variant="`light-danger`"
                      size="104px"
                      rounded
                    />
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <h4
                          v-if="result.holder"
                          class="mb-0"
                        >
                          {{ result.holder.name != null ? result.holder.name : '-' }}
                        </h4>
                        <span class="card-text text-break">{{ result.holder.email != null ? result.holder.email : '-' }}</span>
                        <div class="align-items-center">
                          <!-- Pital Pay -->
                          <div
                            v-for="(wallet, index) in result.holder.wallets"
                            :key="`wallet${index}`"
                            class="d-flex align-items-center mr-2 mt-1"
                          >
                            <b-avatar
                              variant="light-success"
                              rounded
                            >
                              <feather-icon
                                icon="DollarSignIcon"
                                size="18"
                              />
                            </b-avatar>
                            <div class="ml-1">
                              <h5 class="mb-0 text-capitalize">
                                Pital {{ wallet.type }}
                              </h5>
                              <small>
                                Balance: <strong>{{ wallet.balance | formatAmount }}</strong>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
          <!-- Spacer -->
          <hr class="invoice-spacing">
          <!-- User Detail -->
          <b-card-body class="invoice-padding pt-0 pb-0">
            <b-row class="invoice-spacing">
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  User Detail:
                </h6>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Name
                      </td>
                      <td>{{ result.holder.name ? result.holder.name : '-' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Email
                      </td>
                      <td>{{ result.holder.email ? result.holder.email : '-' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Phone Number
                      </td>
                      <td>{{ result.holder.phone_number ? result.holder.phone_number : '-' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Birthdate
                      </td>
                      <td>{{ result.holder.birthdate ? result.holder.birthdate : '-' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Referral Code
                      </td>
                      <td>{{ result.holder.referral_code ? result.holder.referral_code : '-' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        User Registered
                      </td>
                      <td>{{ result.holder.referral.user_registered }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pt-0">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>{{ result.bank_account.bank_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Account Number:
                        </td>
                        <td>{{ result.bank_account.account_number }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Account Name
                        </td>
                        <td>{{ result.bank_account.account_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Status Withdraw:
                        </td>
                        <td>
                          <span
                            class="badge text-capitalize badge-pill"
                            :class="
                              result.status == 'success'
                                ? 'badge-light-success'
                                : result.status == 'pending' ? 'badge-light-warning' : 'badge-light-danger'
                            "
                          >{{ result.status }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Status Reason
                        </td>
                        <td>{{ result.status_reason != null ? result.status_reason : '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Time
                        </td>
                        <td>{{ result.time }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                lg="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Amount:
                    </p>
                    <p class="invoice-total-amount">
                      {{ result.amount | formatAmount }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Admin Fee:
                    </p>
                    <p class="invoice-total-amount">
                      {{ result.admin_fee | formatAmount }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ result.total | formatAmount }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        :xl="result.status == 'pending' ? 3 : 12"
        :md="result.status == 'pending' ? 4 : 12"
        class="invoice-actions"
      >
        <b-card v-if="result.status == 'pending'">
          <!-- Button: Approve -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="approveRejectWithdraw(id, 'approve')"
          >
            Approve Withdraw
          </b-button>

          <!-- Button: Reject -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-75"
            block
            @click="approveRejectWithdraw(id, 'reject')"
          >
            Reject Withdraw
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BTableLite,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Detail Withdraw`
  },
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BCardBody,
    BTableLite,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true

      this.$http.get(`/admin/wallet/pay/withdraw/${this.id}`)
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    approveRejectWithdraw(uuid, type) {
      this.$http.post(`admin/wallet/pay/withdraw/${uuid}/${type}`)
        .then(response => {
          this.getData()
          successNotification(this, 'Success', `Withdraw successfully ${type}`)
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
